import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import authenticated from '../middleware/authenticated'
import middlewarePipeline from '../middleware/middleware-pipeline';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'default',
      auth: false,
    },
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'authenticated',
      auth: true,
      middleware: [authenticated],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      layout: 'authenticated',
      auth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users.vue')
  },
  {
    path: '/conseillers',
    name: 'Conseillers',
    meta: {
      layout: 'authenticated',
      auth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users.vue')
  },
]

Vue.router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

Vue.router.beforeEach((to, from, next) => {
  console.log('to', to);
  console.log('from', from);
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware;
  const context = {
    next,
    axios: Vue.prototype.$axios
  }
  return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
})

export default Vue.router;
