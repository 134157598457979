import Vue from 'vue'

import auth from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'role',
    tokenDefaultKey: 'token',
    authRedirect: { path: '/login' },
    loginData: {
      url: 'auth/local',
      fetchUser: false,
    },
    fetchData: {
      url: 'users/me',
    },
    parseUserData: (data) => {
      if (data && data.user) {
        localStorage.setItem('role', data.user.role.id);
      }
    },
    refreshData: {
      enabled: false,
    },
    notFoundRedirect: { name: '404' },
  }
});


export default {};
