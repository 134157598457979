import Vue from 'vue'
import App from './App.vue'

import router from './router'

import vueAxios from './plugins/vue-axios';
import vueAuth from './plugins/vue-auth';
import vuetify from './plugins/vuetify';
import './plugins/apexcharts';

import Default from './layouts/Default.vue';
import Authenticated from './layouts/Authenticated.vue';

Vue.component('default-layout', Default);
Vue.component('authenticated-layout', Authenticated);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  http: vueAxios,
  router,
  config: vueAuth,
  render: h => h(App)
}).$mount('#app')
