<template>
  <v-app>
    <v-app-bar
      app
      :color="bgColor"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.svg"
          transition="scale-transition"
          width="50%"
        />
      </div>
      <!-- <span class="mr-2">Dashboard</span> -->

      <v-spacer></v-spacer>

      <router-link
        class="link"
        to="/dashboard"
        style="padding-right: 20px"
      >
        Dashboard
      </router-link>

      <router-link
        v-if="checkAuthBenef"
        class="link"
        to="/users"
        style="padding-right: 20px"
      >
        Bénéficiaires
      </router-link>

      <router-link
        v-if="checkAuthConseiller"
        class="link"
        to="/conseillers"
        style="padding-right: 20px"
      >
        Conseillers/CDI
      </router-link>

      <v-btn
        @click="onLogout()"
        icon
        color="#000"
      >
        <v-icon dark>
          mdi-power
        </v-icon>
      </v-btn>

    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'AuthenticatedHeader',
    data () {
      return {
        bgColor: '#D5EDE9',
      };
    },
    computed: {
      checkAuthBenef() {
        return parseInt(localStorage.getItem('role'), 10) === 6 || parseInt(localStorage.getItem('role'), 10) === 3;
      },
      checkAuthConseiller() {
        return parseInt(localStorage.getItem('role'), 10) === 6;
      }
    },
    watch:{
      $route(to) {
        switch(to.name) {
          case 'Users':
            this.bgColor = '#FFF';
            break;
          case 'Dashboard':
            this.bgColor = '#D5EDE9';
            break;
          case 'Conseillers':
            this.bgColor = '#FEF8D4';
            break;
        }
      }
    },
    methods: {
      onLogout() {
        this.$auth
          .logout({
              makeRequest: false,
              redirect: { name: 'Login' },
          });
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        delete this.axios.defaults.headers.common['Authorization'];
      },
    }
  }
</script>
<style lang="scss">
@import '../assets/styles/common.scss';
</style>